import React from 'react';

const TermsAndConditionsScreen = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to EarnFlix! By accessing or using our services, you agree to be bound by these Terms and Conditions. Please read them carefully before proceeding.
      </p>
      <h2>1. Acceptance of Terms</h2>
      <p>
        By using EarnFlix, you acknowledge that you have read, understood, and agree to abide by these Terms and Conditions, as well as our Privacy Policy.
      </p>
      <h2>2. User Accounts</h2>
      <p>
        To access certain features of EarnFlix, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
      </p>
      <h2>3. Prohibited Activities</h2>
      <p>
        You agree not to engage in any of the following prohibited activities while using EarnFlix:
        <ul>
          <li>Violating any applicable laws or regulations.</li>
          <li>Interfering with the operation of EarnFlix or the servers and networks connected to it.</li>
          <li>Attempting to access unauthorized areas of EarnFlix or other user accounts.</li>
          <li>Engaging in any fraudulent or deceptive behavior.</li>
        </ul>
      </p>
      <h2>4. Earnings and Withdrawals</h2>
      <p>
        By using EarnFlix, you agree to abide by our earnings and withdrawal policies. Earnings are calculated based on the time spent on the platform and are subject to eligibility criteria outlined in our Privacy Policy.
      </p>
      <h2>5. Referral Program</h2>
      <p>
        EarnFlix offers a referral program to incentivize user growth. However, referrals must adhere to our guidelines to prevent account restrictions. Referring users from different cities is recommended.
      </p>
      <h2>6. Changes to Terms and Conditions</h2>
      <p>
        EarnFlix reserves the right to update or modify these Terms and Conditions at any time without prior notice. Changes will be effective immediately upon posting. Continued use of EarnFlix constitutes acceptance of the revised Terms and Conditions.
      </p>
      <h2>7. Termination</h2>
      <p>
        EarnFlix reserves the right to terminate or suspend your access to the services at any time, with or without cause, and without prior notice.
      </p>
      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms and Conditions, please contact us at [contact email].
      </p>
    </div>
  );
};

export default TermsAndConditionsScreen;
