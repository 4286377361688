import logo from './logo.svg';
import './App.css';
import PrivacyPolicyScreen from './screens/Privacy';
import TermsAndConditionsScreen from './screens/TOS';

function App() {
  return (
<TermsAndConditionsScreen/>
  );
}

export default App;
